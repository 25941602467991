<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    waiting: {
      type: Boolean,
      default: false,
      required: false,
    },
    lite: {
      type: Boolean,
      default: false,
      required: false,
    },
    isControl: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    toggleEvent() {
      if (this.disabled || this.waiting) return;
      this.$emit("input", !this.value);
    },
  },
  computed: {
    message() {
      return this.value ? "Finalizado" : "Marcar como finalizado";
    },
  },
};
</script>
<template>
  <button
    :class="{ checked: this.value, disabled: disabled, waiting: waiting }"
    class="check-button"
    @click="toggleEvent"
  >
    <span v-show="value && !waiting">
      ✔
      <!-- <i class="fas fa-check"></i> -->
    </span>
    <span v-show="!value && !waiting">
      <i v-if="!isControl" class="far fa-clock"></i>
      <i v-else class="fas fa-xmark"></i>
    </span>
    <span v-show="waiting" class="mr-1">
      <i class="fas fa-spinner fa-spin"></i>
    </span>
    <span v-if="!lite">
      {{ message }}
    </span>
  </button>
</template>
<style scoped>
.check-button {
  min-height: 28px;
  min-width: 28px;
  background: transparent;
  border: solid 1px #cacaca;
  .dark & {
    border: solid 1px rgba(255, 255, 255, 0.25);
  }
  color: silver;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s;
}
.check-button.checked {
  /* verde success */
  background-color: var(--lego-dsuccess);
  color: var(--lego-hsuccess);
  border: solid var(--lego-hsuccess) 1px;
}
.waiting {
  cursor: default;
}
</style>
