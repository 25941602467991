<script>
import Tooltip2Vue from "@/components/Tooltip2Vue/Tooltip2Vue.vue";
import moment from "moment-timezone";
import LegoDateRange from "@/components/Lego/LegoDateRange.vue";

export default {
  components: {
    Tooltip2Vue,
    LegoDateRange,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    position: {
      type: String,
      default: "bottom",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getDatesResumen() {
      if (this.card.startDate && this.card.dueDate) {
        return `${this.useMomentFormat(
          this.card.startDate
        )} - ${this.useMomentFormat(this.card.dueDate)}`;
      } else if (this.card.startDate) {
        return `Desde ${this.useMomentFormat(this.card.startDate)}`;
      } else if (this.card.dueDate) {
        return `Hasta ${this.useMomentFormat(this.card.dueDate)}`;
      } else {
        return "Fechas";
      }
    },
    roleLevel() {
      return this.$store.state.lego.currencyRole.level;
    },
  },
  data() {
    return {
      waitSetDate: false,
    };
  },
  methods: {
    async saveDates(data, close) {
      const card = Object.assign({}, this.card, data);
      // this.waitSetDate = true
      await this.$store.dispatch("lego/setAsyncItemInCard", card);
      // this.waitSetDate = false
      close();
    },
    useMomentFormat(date) {
      return moment(date).tz("America/Bogota").format("D MMM");
    },
  },
};
</script>

<template>
  <Tooltip2Vue
    class="cancel-default-styles"
    :position="position"
    :deleteTooltip="roleLevel > 2"
  >
    <div class="btn-shadow disabled">
      <span><i class="fa-regular fa-calendar-alt"></i></span>
      <span>{{ getDatesResumen }}</span>
    </div>
    <template #tooltip="{ close }">
      <LegoDateRange
        class="tp-container dateTooltip"
        :startDate="card.startDate"
        :dueDate="card.dueDate"
        @setDates="saveDates($event, close)"
      >
        <vs-button class="btn-fijo">
          <div v-show="!waitSetDate">Guardar</div>
          <i class="fas fa-spinner fa-spin" v-show="waitSetDate"></i>
        </vs-button>
      </LegoDateRange>
    </template>
  </Tooltip2Vue>
</template>

<style scoped>
.btn-shadow {
  padding: 0.5rem;
  border: solid 1px #cacaca;
  /* background-color: transparent; */
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  gap: 0.5rem;
  font-size: 0.8rem;
  .dark & {
    border: solid 1px rgba(255, 255, 255, 0.25);
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.tp-container {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 8px;
  cursor: auto;
  width: 300px;
  border: solid 1px rgba(0, 0, 0, 0.25);
  .dark & {
    border: solid 1px rgba(255, 255, 255, 0.25);
  }
}

.dateTooltip {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  text-align: start;
  padding: 1rem;
}

.btn-fijo {
  min-width: 75px;
}
</style>
